@use "src/styles/vars.scss" as *;

.page-our-story {
	position: relative;
	scroll-behavior: smooth;
	background-image: url("https://s3-alpha-sig.figma.com/img/e784/50e7/cb724c206e576f610864bf6e2154bf92?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HqRuGuVSmvXLyL5XshHZGwNmb6kTsB8~9bSMsMwbZ4hbvFmiOFbOGi6xpHSNiSv8maLmmi9pJnIKps5OjD0Wo5YaCwx8g3kmLTVozb~-moQUjJYTbPdBj1911m7EzGSv7mJcHcAG77A5e~-uyAjayLd67CtfumUH90jKXm1oiVlAhxdHRlg5RNHoUMQs8YTXaCsze4uEb~BF3V9dGY8NmtAz0iYeXZyJdIKnphgx7b2PXSappv1~oadT8fSQEPq5XaN043ubu7u2yWiV-Zp69JXGPnQ1xGzlwzekdglq2cxcxvLZCgW52f6nKh8Z1l4b2wDV4xWlXUPCv-TPaFUrZw__");
	background-color: rgba(15, 15, 15, 0.75);
	background-blend-mode: overlay;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	.story {
		height: 100%;

		.timeline {
			display: flex;
			flex-direction: column;
			padding: 60px 8%;
			color: var(--c-white);
			position: relative;
		}
	}
}
