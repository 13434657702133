@use "src/styles/vars.scss" as *;

.app-select-input {
	min-width: 250px;

	@include max-width($scr-xs) {
		width: 100%;
	}

	.select-input {
		&__required {
			color: var(--theme-highlight);
			font-size: var(--text-size-xs);
			font-weight: var(--text-weight-semibold);
			vertical-align: text-top;
		}

		&__error {
			margin: 10px 10px 0 0;
			color: var(--c-error-message);
			font-size: var(--text-size-xs);
			word-break: break-word;
		}

		&__label {
			width: 100%;
			padding: 0px 5px 0 0;
			margin-bottom: 10px;
			color: var(--theme-text-color);
			font-weight: var(--text-weight-semibold);
			display: block;
			@include max-width($scr-xc) {
				margin-bottom: 5.42px;
				font-size: var(--text-size-xs);
			}
		}

		&__input {
			width: 100%;
			color: var(--c-white);
			background-color: transparent;
			border: 0;
			pointer-events: none;
			outline: none;

			&::placeholder {
				color: var(--theme-placeholder);
			}

			&:disabled {
				background-color: transparent;
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&__chevron-icon {
			width: 25px;
			height: 25px;
			padding: 0;
			margin: 0 0 0 10px;
			background-color: transparent;
			border: 0;
			cursor: pointer;
			transform: rotate(90deg);

			img {
				width: 100%;
				@include max-width($scr-xc) {
					height: 10.85px;
					width: 10.85px;
				}
			}

			&--show {
				transform: rotate(-90deg);
			}
		}

		&__icon {
			width: 25px;
			height: 25px;
			margin: 0 0 0 10px;

			img {
				width: 100%;
			}
		}

		&__body {
			width: 100%;
			padding: 12px;
			border-radius: 6px;
			border: 1px solid var(--c-dark-grey);
			background-color: var(--c-box-grey);
			color: var(--c-white);

			cursor: pointer;
			transition: var(--transition);
			display: flex;
			align-items: center;

			&:focus-within {
				border-bottom: 1px solid var(--c-black);
			}

			&--error {
				border: 1px solid var(--c-error-message) !important;
			}

			&--custom-width {
				width: 100%;
				max-width: 387px;

				@include min-width($scr-lg) {
					width: 387px;
				}
			}

			@include max-width($scr-xc) {
				height: 29px;
				padding: 8.68px 10.85px;
			}
		}

		&--error {
			.input {
				&__body {
					border: 1px solid var(--c-error-message);
				}
			}
		}

		&--disabled {
			.input {
				&__label {
					background-color: var(--c-disabled);
				}

				&__body {
					background-color: var(--c-disabled);
				}
			}
		}
	}

	.select-options {
		position: fixed;
		inset: 0;
		width: 100%;
		height: 100%;
		padding: 0 10px;
		z-index: 999;

		&__filter-input {
			position: sticky;
			top: 0;
			width: 100%;
			padding: 15px;
			color: var(--c-white);
			background-color: var(--c-box-grey);
			border: 0;
			border-bottom: 1px solid var(--c-border-grey);
			outline: none;

			&::placeholder {
				color: var(--theme-placeholder);
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&__options {
			position: absolute;
			width: 100%;
			min-height: 44px;
			max-height: 300px;
			padding: 10px 0;
			margin: 0;
			background-color: var(--c-box-grey);
			border-radius: 8px;
			overflow-y: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			&--searchable {
				padding: 0 0 10px;
			}
		}

		&__option {
			list-style-type: none;

			&:not(&:last-child) {
				border-bottom: 1px solid var(--c-border-grey);
			}

			&--active {
				pointer-events: none;

				.select-options {
					&__button {
						color: var(--c-box-grey);
						background-color: var(--c-body);
					}
				}
			}
		}

		&__option-text {
			padding: 10px 0 0;
			list-style-type: none;
		}

		&__fetching {
			padding: 0 15px;
			margin: 0;
			color: var(--theme-text-color-3rd);

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}

		&__empty {
			padding: 0 15px;
			margin: 0;
			color: var(--theme-text-color-3rd);
		}

		&__button {
			width: 100%;
			text-align: left;
			padding: 10px 15px;
			color: var(--c-grey);
			background-color: transparent;
			font-size: var(--text-size-s);
			border: 0;
			cursor: pointer;
			transition: var(--transition);

			&:disabled {
				color: var(--theme-text-color-3rd);
				background-color: var(--c-body);
			}

			&:hover {
				background-color: var(--c-body);
				color: var(--c-box-grey);
			}

			@include max-width($scr-xc) {
				font-size: var(--text-size-xxs);
			}
		}
	}
}
