@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:800");

:root {
	/* -- Color -- */
	--c-white: #ffffff;
	--c-soft-White: #e4e4e7;
	--c-light-grey: #d9d9d9;
	--c-dark-grey: #262626;
	--c-darker-grey: #5f6060;
	--c-grey: #999999;
	--c-mid-grey: #666666;
	--c-black: #4d4d4d;
	--c-pure-black: #000000;
	--c-bg-black: #080808;
	--c-dark-black: #0f0f0f;
	--c-transparent-black: #00000080;
	--c-sky-blue-mist: #bbcee5;

	--c-body: #f3f3f6;
	--c-disabled: #eaeaea;
	--c-error-message: #ff0404;
	--c-button-black: #141414;
	--c-border-grey: #1f1f1f;
	--c-box-grey: #1a1a1a;
	--c-pagination-highlight-1: #333333;
	--c-pagination-highlight-2: #fdbc11;
	--c-nav-grey: #050b3b40;
	--c-divider: #d9d9d940;
	--c-sidenav-bg: #0f0f0ff2;
	--c-sidenav-border: #374151;

	/* -- Theme Color Set -- */
	--theme-highlight: #3069b2;
	--theme-background-color: var(--c-bg-black);
	--theme-text-color: var(--c-white);
	--theme-text-color-2nd: var(--c-grey);
	--theme-text-color-3rd: var(--c-light-grey);
	--theme-desc-color: #98989a;
	--theme-terms-color: var(--c-grey);
	--theme-placeholder: var(--c-mid-grey);
	--theme-chatbot-btn: #116ad3;

	/* -- Variables -- */
	--title-font-size: 36px;
	--article-title-font-size: 34px;
	--title-mobile-font-size: 32px;
}

[data-theme="default"] {
	--theme-background-color: var(--c-bg-black);
}

[data-theme="theme2"] {
	--theme-background-color: #221d23;
	--theme-highlight: #d1603d;
	--c-sidenav-bg: #4f3824f2;
	--c-sidenav-border: #ddb967;
	--theme-chatbot-btn: #d0e37f;
}
